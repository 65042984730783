import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby";
import { v4 } from 'uuid';

import { ContentDefault } from 'components/Elements'
import Content from "components/Content";
import "./GravityForm.scss";
import { chunkArray, calculateColumn } from "../../utils";

const GravityForm = ({ id, perColumn }) => {
  const { allGfForm: { edges: gravityData } } = useStaticQuery(graphql`
    query {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            descriptionPlacement
            formFields {
              id
              label
              labelPlacement
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              isRequired
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  const { node: gfForm } = gravityData.filter(({ node }) => node.formId === id)[0];

  const [fields, setFields] = useState({});
  const [form, setForm] = useState(gfForm);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    let tempForm = form;

    // add submit button as a field
    if (tempForm.formFields.filter((item) => item.type === "submit").length === 0)  {
      tempForm.formFields = [
        ...tempForm.formFields,
        {
          formId: v4(),
          type: "submit",
          text: tempForm.button.text
        }
      ];
    }

    if (perColumn) {
      tempForm = {
        ...tempForm,
        formFields: chunkArray(tempForm.formFields, perColumn)
      };
    }

    setForm({
      ...form,
      ...tempForm
    });
  }, [])

  async function handleOnSubmit(event) {
    event.preventDefault();

    if (status === 'processing') {
      return;
    }

    setStatus('processing');

    try {
      const formData = new FormData();

      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, value);
      }

      const request = await fetch(`${form.apiURL}/submissions`, {
        method: 'POST',
        body: formData
      });

      const response = await request.json();
      if (response.is_valid === true) {
        setStatus('done');

        setMessage(response.confirmation_message);
      } else {
        setStatus('error');
        console.log(response);
      }
    } catch (error) {
      setStatus('error');
      console.error(error);
    }
  }

  function handleFieldChange(event) {
    // eslint-disable-next-line prefer-destructuring
    let value = event.target.value;

    if (event.target.type === "checkbox") {
      value = event.target.checked ? event.target.value : "";
    }

    setFields({
      ...fields,
      [event.target.name]: value
    });
  }

  if (status === "done") {
    return <ContentDefault className="form-submission-message">
      <div dangerouslySetInnerHTML={{ __html: message }} />
    </ContentDefault>
  }

  if (form.formFields) {
    return (
      <form method="post" className={`form form_${form.formId}`} onSubmit={handleOnSubmit}>
        {status === "processing" && (
          <Loading />
        )}
        <div className="row">
          {form.formFields && form.formFields.map((field, key) => {
            if (Array.isArray(field)) {
              return (
                <div key={key} className={`col-lg-${calculateColumn(form.formFields.length)}`}>
                  {field.map((item, index) => (
                    <FormField key={index} field={item} fields={fields} onChange={handleFieldChange} />
                  ))}
                </div>
              )
            }

            return (
              <div className="col-lg-12">
                <FormField key={key} field={field} fields={fields} onChange={handleFieldChange} />
              </div>
            )
          })}
        </div>
      </form>
    )
  }

  console.error("No gravity forms found with id", id);
  return false;
}

const TextField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <div className="form-group">
    {labelPlacement !== "hidden_label" && <label htmlFor={`input_${id}`}>{label}</label>}
    <input
      value={value}
      onChange={onChange}
      type="text"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const EmailField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <div className="form-group">
    {labelPlacement !== "hidden_label" && <label htmlFor={`input_${id}`}>{label}</label>}
    <input
      value={value}
      onChange={onChange}
      type="email"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const PhoneField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <div className="form-group">
    {labelPlacement !== "hidden_label" && <label htmlFor={`input_${id}`}>{label}</label>}
    <input
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const FileField = ({ value, onChange, field: { id, type, label, allowedExtensions, cssClass, isRequired, placeholder } }) => {

  function handleChange(file) {
      onChange({
        target: {
          name: `input_${id}`,
          value: file
        }
      })
  }

  function handleFileUpload(e) {
    const files = e.target.files;

    for (let i = 0; i < files.length; i++) {
      handleChange(files[i])
    }
  }

  function handleFileDrop(e) {
    e.preventDefault();
    console.log(e);

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          handleChange(e.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        handleChange(e.dataTransfer.files[i]);
      }
    }
  }

  return (
    <div className="form-upload" onDrop={handleFileDrop} onDragOver={e => e.preventDefault()}>
      
      <input
        type="file"
        onChange={handleFileUpload}
        id={`input_${id}`}
        className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
        name={`input_${id}`}
        placeholder={placeholder}
        accept={allowedExtensions}
      />
    </div>
  )
}

const TextAreaField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <div className="form-group">
    {labelPlacement !== "hidden_label" && <label htmlFor={`input_${id}`}>{label}</label>}
    <textarea
      value={value}
      onChange={onChange}
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const CheckboxField = ({ value, onChange, field: { id, type, cssClass, choices } }) => {
  const list = JSON.parse(choices);

  return (
    <div className="form-group">
      {list.map((checkbox, key) => (
        <div key={key} className="form-group__checkboxes">
          <input
            checked={value}
            onChange={onChange}
            type="checkbox"
            id={`input_${id}_${key + 1}`}
            className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
            name={`input_${id}_${key + 1}`}
            value={checkbox.value}
          />
          <label htmlFor={`input_${id}_${key + 1}`} className="checkbox-content">{checkbox.text}</label>
        </div>
      ))}
    </div>
  )
}

const SubmitButton = ({ field }) => (
  <div className="form-footer">
    <button className="button form-submit" type="submit">
      {field.text}
    </button>
  </div>
)

export const FormField = ({ field, fields, onChange }) => (
  <>
    {field.type === "text" && <TextField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "email" && <EmailField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "phone" && <PhoneField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "textarea" && <TextAreaField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "checkbox" && <CheckboxField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "fileupload" && <FileField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "submit" && <SubmitButton field={field} />}
  </>
)

export const Loading = () => (
  <div className="form-loading">
    <div className="form-loading-spinner">
      <div className="sk-chase">
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
      </div>
    </div>
  </div>
);

export default GravityForm;


